import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Why Typography Matters?`}</h2>
    <p>{`Typography is all about using typefaces and fonts in a way that makes the copy legible, clear, and enjoyable to read. Typography involves font style, appearance, and structure, aiming to elicit certain emotions and convey specific messages to the end-user. As well as ensuring the typeface lends itself to the visual balance of the website or app. Typography is an integral part of almost every digital experience because it acts as a glue that holds the different parts of a product. You should consider various aspects of typography (typefaces, font sizes, widths, colors, etc.) because all of them work together to create a usable and beautiful system. The ultimate goal is to make the copy feel pleasant to the eye. A study shows that 70 % of User Interface depends on typography as it is the best source of communicating with anyone.`}</p>
    <h2>{`Defining your typography`}</h2>
    <p>{`There are thousands of fonts available for creatives to choose from. However, when it comes to picking a typeface, you can’t rely on gut alone. Making the right choice depends on function, context and a whole host of other factors.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Think about personality and branding`}</strong>{` `}<br />{`
A font you select should embody the character and spirit of your brand. Try to match the font style to your brand’s character. It’s important for consider the personality of the product and how the typefaces they’re considering reinforce or clash with that personality. For example, using Comic Sans on a website for a law firm would clash.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Keep legibility, functionality and accessibility top of mind`}</strong>{` `}<br />{`
Consider your user, their environment and the medium with which they’re engaging with your product. color and contrast can make or break accessibility. Using fancy fonts or uppercase text in large bodies of text as it forces strain on the reader’s eye. And not every typeface looks good at every weight and size. Display fonts that look amazing in larger sizes can become illegible at smaller sizes. Typefaces that look great at small sizes in body text can sometimes look too plain or even boring when used at display sizes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Make sure have a good readability`}</strong>{` `}<br />{`
Readability is arguably the most important feature of a typeface. Since type is used to communicate a message, if that message can’t be read, the typeface has failed its job.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Have large font families`}</strong>{` `}<br />{`
Large font families, such as Roboto or Baskerville, that have multiple weights and styles can make it easier for designers to create complex typographic designs without the stress of figuring out which typefaces work well together. Some large font families even include both serif and sans serif versions, which offers even more flexibility.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Great visual and versatility`}</strong>{` `}<br />{`
Fonts that are perfect for use on the web might not translate well to use in print, and vice versa. Make sure the font will work in every medium in which it may be used.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Match your font style`}</strong>{` `}<br />{`
There are four basic fonts styles: serif, sans serif, display, and script. Serif fonts are often viewed as more traditional and formal (though not all are). Sans serif typefaces can be seen as more modern and minimalist. Display fonts are unsuitable for use at small sizes, but their appearance varies widely. Script fonts resemble handwriting or calligraphy. Both script and display fonts are used primarily for short blocks of text or things like headlines and titles.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Pair up properly`}</strong>{` `}<br />{`
If you’re trying to pair two typefaces, start by defining what you want to achieve: are you aiming for harmony or contrast? Are you looking for complementary typefaces with corresponding curves, for example? Be careful not to let things get too uniform. Not all fonts play well with others. Some typefaces are neutral enough that they can be paired with hundreds of other fonts. But others have such unique character that suitable combinations are limited.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Typography rules`}</h2>
    <p>{`Here are some typography rules to keep in mind when designing your website or app for optimal user-friendliness. It’s by no means an exhaustive list, but it provides a good starting point in your burgeoning typography practice.`}</p>
    <ul>
      <li parentName="ul">{`Ensure that the typography of your design mirrors the personality of the brand you are designing.`}</li>
      <li parentName="ul">{`Create a big difference when you want to create contrast when you have two elements, for example, a header and body of text.`}</li>
      <li parentName="ul">{`Choose a suitable typeface and font for your designs.`}</li>
      <li parentName="ul">{`Use similar font when designing for the same brand.`}</li>
      <li parentName="ul">{`It is preferable to stick to the two types of families, the Serif or Sans-serif, to create contrast, except you are intentionally trying to make your design look messy.`}</li>
      <li parentName="ul">{`It is better to pair a regular and an italic font to make emphasis.`}</li>
      <li parentName="ul">{`Ensure that your header is two times bigger than the body of the text.`}</li>
      <li parentName="ul">{`Creating the proper contrast between the typeface and background colors is vital.`}</li>
      <li parentName="ul">{`Do not download more character sets of a font when downloading a web font to avoid unnecessary weight.`}</li>
      <li parentName="ul">{`To be able to create a big contrast, try pairing fonts that have different weights.`}</li>
      <li parentName="ul">{`Endeavor to use the same icon and font when designing to maintain consistency.`}</li>
      <li parentName="ul">{`It is better to pair a regular and an italic font to make emphasis.`}</li>
      <li parentName="ul">{`Above all, ensure you consider all the elements of typography when designing.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      